<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>Permissions</CCardHeader>
                    <CCardBody>
                        <CTabs variant="pills">
                            <CTab title="Methods" active>
                                <br>
                                <CRow>
                                    <CCol sm="12">
                                        <CDataTable hover striped :items="modules_items" :fields="modules_fields"
                                            :noItemsView="{ noItems: 'No items to show.' }">
                                            <template #action="data">
                                            <td>
                                                <template>
                                                    <div class="flex-container">
                                                        <div @click="get_methods();module_name=data.item.name;methods_modal=true"
                                                            v-c-tooltip.hover="'Methods'">
                                                        <CIcon height="20" :content="$options.permissions"/>
                                                        </div>
                                                    </div>
                                                </template>
                                            </td>
                                        </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Dashboard">
                                <br>
                                <CRow>
                                    <CCol sm="12">
                                        <CForm @keyup.enter="submit_rows()">
                                            <CRow>
                                                <CCol sm="6">
                                                    <p><strong>Include Field Values</strong></p>
                                                    <CInputCheckbox v-for="(option, index) in include_rows" v-model="option.value"
                                                        true-value="true" false-value="false" :key="index"
                                                        :label="option.label" :checked="option.value === true"
                                                        @change="option.value=$event.target.checked"
                                                    />
                                                    <!-- <div v-for="(option, index) in include_rows" :key="`i${index}`"
                                                        role="group" class="form-check">
                                                        <input
                                                            @change="option.value = $event.target.checked"
                                                            :checked="option.value === true"
                                                            type="checkbox" class="form-check-input" >
                                                        <label class="form-check-label"> {{option.label}} </label>
                                                    </div> -->
                                                </CCol>
                                                <CCol sm="6">
                                                    <p><strong>Except Field Values</strong></p>
                                                    <!-- <CInputCheckbox v-for="option in except_rows" v-model="option.value"
                                                        true-value="true" false-value="false" :key="option.label"
                                                        :label="option.label" :checked="option.value === true"
                                                        @change="option.value=$event.target.checked"
                                                    /> -->
                                                      <!-- <div v-for="(option, index) in except_rows" :key="`e${index}`"
                                                        role="group" class="form-check">
                                                        <input v-model="option.value"
                                                            @change="option.value = $event.target.checked"
                                                            :checked="option.value === true"
                                                            type="checkbox" true-value="true" false-value="false" class="form-check-input" >
                                                        <label class="form-check-label"> {{option.label}} </label>
                                                    </div> -->
                                                </CCol>
                                            </CRow>
                                            <br>
                                            <CRow>
                                                <CCol sm="12">
                                                    <strong>
                                                        <!-- <CInputCheckbox v-model="limit_include_field[0].value"
                                                            true-value="true" false-value="false" key="limit_include_field"
                                                            :label="limit_include_field[0].label" :checked="limit_include_field[0].value === true"
                                                            @change="limit_include_field[0].value = $event.target.checked"
                                                        /> -->
                                                          <div v-for="(option, index) in limit_include_field" :key="`l${index}`"
                                                            role="group" class="form-check">
                                                            <input v-model="option.value"
                                                                @change="option.value = $event.target.checked"
                                                                :checked="option.value === true"
                                                                type="checkbox" true-value="true" false-value="false" class="form-check-input" >
                                                            <label class="form-check-label"> {{option.label}} </label>
                                                        </div>
                                                    </strong>
                                                    <strong>
                                                        <!-- <CInputCheckbox v-model="delete_field[0].value"
                                                            true-value="true" false-value="false" key="delete_field"
                                                            :label="delete_field[0].label" :checked="delete_field[0].value === true"
                                                            @change="delete_field[0].value = $event.target.checked"
                                                        /> -->
                                                          <!-- <div v-for="(option, index) in delete_field" :key="`d${index}`"
                                                            role="group" class="form-check">
                                                            <input v-model="option.value"
                                                                @change="option.value = $event.target.checked"
                                                                :checked="option.value === true"
                                                                type="checkbox" true-value="true" false-value="false" class="form-check-input" >
                                                            <label class="form-check-label"> {{option.label}} </label>
                                                        </div> -->
                                                    </strong>
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                        <br>
                                        <div class="form-group form-actions">
                                            <CButton @click="submit_GET" color="primary">submit</CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Methods"
            size="sm"
            color="info"
            :show.sync="methods_modal"
            >
            <CForm @keyup.enter="submit_methods">
                <CRow>
                    <CCol sm="12">
                        <CInputCheckbox v-for="option in perm_array" v-model="option.value"
                            true-value="true" false-value="false" :key="option.label"
                            :label="option.label" :checked="option.value === true"
                            @change="option.value = $event.target.checked"
                        />
                    </CCol>
                </CRow>
            </CForm>
            <template #footer>
                <CButton @click="submit_methods" color="primary">submit</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { cilGrid } from '@coreui/icons'
export default {
    permissions: cilGrid,
    data () {
        return {
            modules_items:[
                {name: 'arbitrage.DataSourceLogs', url: '/arbitrage/data_source_logs/'},
                {name: 'arbitrage.DataSources', url: '/arbitrage/data_sources/'},
                {name: 'arbitrage.InputDatas', url: '/arbitrage/input_datas/'},
                {name: 'arbitrage.InputProccess', url: '/arbitrage/input_proccesses/'},
                {name: 'arbitrage.InputProccessTriggers', url: '/arbitrage/input_proccess_triggers/'},
                {name: 'arbitrage.OutputDatas', url: '/arbitrage/output_datas/'},
                {name: 'arbitrage.SystemCustomValues', url: '/arbitrage/system_custom_values/'},
                {name: 'system.GuestUserTokens', url: '/system/guest_user_tokens/'},
                {name: 'system.TelegramAccounts', url: '/system/telegram_accounts/'},
                {name: '"system.User', url: '/system/users/'},
                {name: 'system.UserGroup', url: '/system/user_groups/'},
                {name: 'system.UsersToUserGroup', url: '/system/users_to_user_groups/'},
            ],
            modules_fields: [
                { key: 'name', label: 'Name' },
                { key: 'action', label: 'Set Permissions' },
            ],
            module_name: '',
            methods_modal: false,
            perm_array: {
                get_action: {label: "Get Data", value: false},
                post_action: {label: "Post Data", value: false},
                put_action: {label: "Update Data", value: false},
                delete_action: {label: "Delete Data", value: false},
            },
            include_rows: {},
            include_rows_ids: [],
            except_rows: {},
            except_rows_ids: [],
            limit_include_field: {
                0: {label: 'Limit with Include Field Values', value: false}
            },
            delete_field: {
                0: {label: 'Delete Field', value: false}
            }
        }
    },
    mounted(){
        this.get_rows();
    },
    methods: {
        get_methods(){
            this.axios.get('system/user_groups/'
                + '?id=' + this.$route.params.id
                + '&perm_array=1')
                .then(res => {
                    if(`${this.module_name}` in res.data[0].perm_array.ObjectPermissions){
                        for(const key in res.data[0].perm_array.ObjectPermissions[this.module_name].main_object){
                            for(const k in this.perm_array){
                                if(key == k){
                                    this.perm_array[k].value = res.data[0].perm_array.ObjectPermissions[this.module_name].main_object[k].can_do_action;
                                }
                            }
                        }
                    }
                })
        },
        submit_methods(){
            Object.keys(this.perm_array).forEach((key) => {
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                data.set('object_type', 'main_object');
                data.set('action_type', key);
                data.set('module_type', this.module_name);
                data.set('each_action_type', 'can_do_action');
                data.set('can_do_action', this.perm_array.get_action.value);
                this.axios.put('system/user_groups/', data)
                .then(() => {
                    this.methods_modal = false;
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        text: `${key} Submitted Successfully.`
                    });
                })
            })
        },
        get_rows(){
            this.axios.get('arbitrage/input_proccesses/')
                .then(res => {
                    this.limit_include_field[0].value = false;
                    this.include_rows = {};
                    this.except_rows = {};
                    res.data.map(val => {
                        this.include_rows[val.id] = {label: val.name, value: false};
                        this.except_rows[val.id] = {label: val.name, value: false};
                    });
                })
                .finally(() => {
                    this.axios.get('system/user_groups/'
                    + '?id=' + this.$route.params.id
                    + '&perm_array=1')
                    .then(res => {
                        if('arbitrage.InputProccess' in res.data[0].perm_array.ObjectPermissions){
                            const include_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.include_field_values;
                            const except_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.except_field_values;
                            const limit_with_include_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.limit_with_include_field_values;
                            for(const i in include_field_values){
                                for(const k in this.include_rows){
                                    if(include_field_values[i] == k){
                                        this.include_rows[k].value = true;
                                    }
                                }
                            }
                            for(const i in except_field_values){
                                for(const k in this.except_rows){
                                    if(except_field_values[i] == k){
                                        this.except_rows[k].value = true;
                                    }
                                }
                            }
                            this.limit_include_field[0].value = limit_with_include_field_values;
                        }
                    })
                })
        },
        submit_GET(){
            this.include_rows_ids = [], this.except_rows_ids = [];
            for(const i in this.include_rows){
                if(this.include_rows[i].value == true){
                    this.include_rows_ids.push(parseInt(i));
                }
            }
            for(const i in this.except_rows){
                if(this.except_rows[i].value == true){
                    this.except_rows_ids.push(parseInt(i));
                }
            }
            const data = new FormData();
            data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
            data.set('object_type', 'main_object');
            data.set('action_type', 'get_action');
            data.set('module_type', 'arbitrage.InputProccess');
            if(this.delete_field[0].value){
                data.set('each_action_type', 'delete_field');
            } else {
                data.set('each_action_type', 'update_field');
            }
            data.set('field_name', 'id');
            data.set('include_field_values', JSON.stringify(this.include_rows_ids));
            if(!this.delete_field[0].value){
                data.set('limit_with_include_field_values', this.limit_include_field[0].value);
            }
            data.set('except_field_values', JSON.stringify(this.except_rows_ids))
            this.axios.put('system/user_groups/', data)
                .then(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        text: 'Submitted Successfully.'
                    });
                })
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
